<template>
  <SfOImage
    class="m-brands-carousel__image"
    :alt="name"
    :src="urlLogoBrand"
    :enable-events="true"
    @on-error="onError"
  />
</template>
<script>
import { getBrandImagePath } from 'theme/helpers'
import SfOImage from 'theme/components/storefront-override/SfOImage.vue';

export default {
  name: 'MRenderImage',
  data () {
    return {
      show: true
    }
  },
  components: {
    SfOImage
  },
  props: {
    brandId: String,
    name: String
  },
  computed: {
    urlLogoBrand () {
      return getBrandImagePath(this.brandId)
    }
  },
  methods: {
    onError () {
      this.$emit('loading-error', this.brandId)
    }
  }
}
</script>
